<template>
	<div class="content">
		<p class="title">Top 30 Funders of TB Research</p>
		<p class="subtitle">2020</p>

		<table>
			<tr class="head">
				<th style="text-align: left; width: 80px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-left: 5px">
					Rank <i v-if="sortOrder === 1" @click="sort('Rank')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('Rank')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
				<th style="text-align: left; width: 300px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-left: 5px">
					Funder <i v-if="sortOrder === 1" @click="sort('Funder')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('Funder')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
				<th style="text-align: right; width: 110px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-right: 5px">
					Funder Type <i v-if="sortOrder === 1" @click="sort('FunderType')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('FunderType')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
				<th style="text-align: right; width: 120px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-right: 5px">
					2020 Funding <i v-if="sortOrder === 1" @click="sort('Funding')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('Funding')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
			</tr>
			<tr v-for="(info, index) in sortedTable" :key="index">
				<td class="left">{{ info.Rank }}</td>
				<td class="left">{{ info.Funder }}</td>
				<td class="right">{{ info.FunderType }}</td>
				<td class="right">${{ info.Funding }}</td>
			</tr>
		</table>

		<div class="label">
			<span> C = Corporation/Private Sector</span>
			<span style="margin-left: 30px"> F = Foundation/Philantrophy</span>
		</div>
		<div class="label">
			<span> M = Multilateral</span>
			<span style="margin-left: 30px"> P = Public-Sector R&D Agency</span>
		</div>
	</div>
</template>

<script>
	import table1 from '@/tables/tabela1.json';
	export default {
		data() {
			return {
				data: table1,
				sortBy: 'Rank',
				sortOrder: 1,
			};
		},

		methods: {
			sort: function (sortBy) {
			
				if (this.sortBy === sortBy) {
					this.sortOrder = -this.sortOrder;
				} else {
					this.sortBy = sortBy;
				}
			},
		},

		computed: {
			sortedTable() {
				return (
					[...this.data]
						.map((i) => ({ ...i, Funding: parseInt(i.Funding) }))
						.sort((a, b) => {
							if (a[this.sortBy] >= b[this.sortBy]) {
								return this.sortOrder;
							}
							return -this.sortOrder;
						})
				);
			},
		},
	};
</script>

<style scoped>
	.content {
		widows: 566px;
	}

	.title,
	.subtitle {
		color: white;
		text-align: start;
		margin-left: 10px;
	}

	.title {
		font-size: 26px;
		margin-top: 8px;
		margin-bottom: 0;
		font-family: 'Museo500';
	}
	.subtitle {
		font-size: 20px;
		margin-top: 10px;
		font-family: 'Museo100';
	}

	.head th {
		background-color: #d50032;
		color: white;
		font-family: 'Museo100';
		height: 40px;
		font-weight: 100 !important;
		border-left: 0.5px solid #1e213a;
		border-right: 0.5px solid #1e213a;
	}

	.left {
		text-align: left;
		color: rgb(214, 213, 213);
		font-family: 'Museo100';
		font-weight: 100;
		font-size: 13px;
		padding: 5px;
	}
	.right {
		text-align: right;
		color: rgb(214, 213, 213);
		font-family: 'Museo100';
		font-weight: 100;
		font-size: 13px;
		padding: 5px;
	}
	table {
		border: none;
		border-collapse: collapse;
		margin-left: 10px;
		margin-right: 10px;
	}

	table td {
		border-left: 0.5px solid #1e213a;
		border-right: 0.5px solid #1e213a;
	}

	tr {
		border: solid #292e55;
		border-width: 0.5px 0;
	}

	tr:first-child {
		border-top: none;
	}

	.label {
		color: rgb(214, 213, 213);
		font-family: 'Museo100';
		font-weight: 100;
		font-size: 13px;
		padding: 10px;
		text-align: left;
	}
</style>
