<script>
	import { Doughnut } from 'vue-chartjs';
	import graph from '@/charts/chart7.json';
	export default {
		extends: Doughnut,
		name: 'ChartFour',
		data: () => ({
			data: graph,
			chartdata: {
				labels: [
					['U.S. National Institutes of Health (NIH)'],
					['European Commission'],
					['Korean Ministry of Science and ICT'],
					['U.K. Medical Research Council (U.K. MRC)'],
					['Australian National Health', 'and Medical Research Council (NHMRC)'],
					['U.K. Biotechnology and Biological', 'Sciences Research Council (BBSRC)'],
					['German Federal Ministry of Research', 'and Education (BMBF)'],
					['Swiss National Science Foundation (SNSF)'],
					['Funders under 2%'],
				],

				datasets: [
					{
						backgroundColor: ['#D50032', '#AF71D3', '#D9615C', '#ED815B', '#7BCF9C', '#436FF2', '#F77FB0', '#5DB9DC', '#F7CD5B'],
						data: [],
						borderWidth: 0,
						percent: [74, 3, 2, 2, 2, 2, 2, 2, 11],
					},
				],
			},

			options: {
				cutoutPercentage: 80,
				legend: {
					display: false,
				},
				responsive: true,
				maintainAspectRatio: false,

				title: {
					display: true,
					text: '',
					fontColor: 'white',
					fontSize: 29,
					fontFamily: 'Museo100',
				},
				layout: {
					padding: 20,
				},
				tooltips: {
					bodyFontFamily: 'Museo100',

					callbacks: {
						title: function (tooltipItem, data) {
							return data['labels'][tooltipItem[0]['index']];
						},
						label: function (tooltipItem, data) {
							return ' ' + data['datasets'][0]['data'][tooltipItem['index']] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%';
						},
					},

					/* callbacks: {
						label: function (tooltipItem, data) {
							console.log(tooltipItem.index);
							console.log(data.labels[tooltipItem.index][0]);
							console.log(data.labels[tooltipItem.index][1]);

							if (tooltipItem.index === 0) {
								if (data.labels[tooltipItem.index].length === 2) {
									return [
										data.labels[tooltipItem.index][0],
										data.labels[tooltipItem.index][1],
										data.datasets[0].data[tooltipItem.index] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%',
									];
								} else
									return [
										data.labels[tooltipItem.index][0] +
											': ' +
											data.datasets[0].data[tooltipItem.index] +
											' | ' +
											data['datasets'][0]['percent'][tooltipItem['index']] +
											'%',
									];
							} else {
								console.log();
								if (data.labels[tooltipItem.index].length === 2) {
									return [
										data.labels[tooltipItem.index][0],
										data.labels[tooltipItem.index][1] + ': ',
										data.datasets[0].data[tooltipItem.index] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%',
									];
								} else
									return [
										data.labels[tooltipItem.index][0] +
											': ' +
											data.datasets[0].data[tooltipItem.index] +
											' | ' +
											data['datasets'][0]['percent'][tooltipItem['index']] +
											'%',
									];
							}
						},
					}, */
				},
			},
		}),
		mounted() {
			const middleText = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 500).toFixed(2);
				ctx.font = `${fontSize}rem Museo100`;
				ctx.textBaseline = 'middle';

				const text = 'Total funding';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText,
			});
			const middleText2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 400).toFixed(2);
				ctx.font = `${fontSize}rem Museo500`;
				ctx.textBaseline = 'middle';

				const text = '$163.332.777';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.7;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText2,
			});

			const customPluginTitle = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);

				ctx.font = `${fontSize}rem Museo300`;

				ctx.textBaseline = 'middle';

				const text = 'Basic Science';
				const textX = Math.round((width - ctx.measureText(text).width) / 16);
				const textY = height / 20;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle,
			});

			this.chartdata.datasets[0].data.push(this.data[0].BasicScience);
			this.chartdata.datasets[0].data.push(this.data[1].BasicScience);
			this.chartdata.datasets[0].data.push(this.data[2].BasicScience);
			this.chartdata.datasets[0].data.push(this.data[3].BasicScience);
			this.chartdata.datasets[0].data.push(this.data[4].BasicScience);
			this.chartdata.datasets[0].data.push(this.data[5].BasicScience);
			this.chartdata.datasets[0].data.push(this.data[6].BasicScience);
			this.chartdata.datasets[0].data.push(this.data[7].BasicScience);
			this.chartdata.datasets[0].data.push(this.data[8].BasicScience);

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: transparent;
		color: white;
	}
</style>
