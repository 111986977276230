<script>
	import { Bar } from 'vue-chartjs';
	import graph from '@/charts/chart6.json';
	export default {
		extends: Bar,
		name: 'ChartOne',
		data: () => ({
			data: graph,
			chartdata: {
				labels: [],
				datasets: [
					{
						label: '2018 + 2019 + 2020 funding',
						data: [],
						borderColor: '#436FF2',
						backgroundColor: '#D50032',

						order: 0,
					},
					{
						label: 'Global Plan 5-Year Target',
						yAxisID: 'A',
						data: [],
						backgroundColor: '#ED815B',
						order: 0,
					},
				],
			},

			options: {
				legend: {
					labels: {
						fontColor: 'white',
						fontSize: 16,
						fontFamily: 'Museo100',
						boxWidth: 3,
					},
					position: 'bottom',
					align: 'start',
				},

				maintainAspectRatio: false,

				title: {
					display: true,
					text: [' ', '', '', ''],
					fontColor: 'white',
					fontSize: 18,
					fontFamily: 'Museo100',
				},

				scales: {
					yAxes: [
						{
							id: 'A',

							ticks: {
								maxTicksLimit: 5,

								fontStyle: 'normal',
								fontFamily: 'Museo100',
							},
							stacked: false,
						},
					],
					xAxes: [
						{
							ticks: {
								maxTicksLimit: 5,

								fontStyle: 'normal',
								fontFamily: 'Museo100',
							},
							stacked: true,
						},
					],
				},
				tooltips: {
					bodyFontFamily: 'Museo100',
				},
			},
		}),
		methods: {
			getWindowWidth() {
				this.size = window.innerWidth;

				if (this.size > 1439) {
					this.options.legend.labels.fontSize = 16;
				} else this.options.legend.labels.fontSize = 12;
			},
		},
		mounted() {
			this.getWindowWidth();
			const customPluginTitle = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);
				if (this.size > 1439) {
					ctx.font = `${fontSize}rem Museo300`;
				} else ctx.font = `${16}px Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Progress toward Global Plan 5-year';
				const textX = Math.round((width - ctx.measureText(text).width) / 16);
				const textY = height / 20;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle,
			});
			const customPluginTitle2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);
				if (this.size > 1439) {
					ctx.font = `${fontSize}rem Museo300`;
				} else ctx.font = `${16}px Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Research Funding Targets (M$)';
				const textX = Math.round((width - ctx.measureText(text).width) / 18);
				const textY = height / 9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle2,
			});
			for (let index = 0; index < this.data.length; index++) {
				this.chartdata.labels.push(this.data[index].ResearchArea);
				this.chartdata.datasets[1].data.push(this.data[index].Global);
				this.chartdata.datasets[0].data.push(this.data[index].threeYearFunding);
			}

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: transparent;
		color: white;
	}
</style>
