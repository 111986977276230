<template>
	<div class="content">
		<p class="title">Majority of Countries have not Met TB R&D</p>
		<p class="title" style="margin-bottom: 16px">Fair Share Funding Targets</p>

		<table>
			<tr class="head">
				<th style="text-align: left; width: 140px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-left: 5px">
					Rank <i v-if="sortOrder === 1" @click="sort('Rank')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('Rank')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
				<th style="text-align: left; width: 90px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-left: 5px">
					Country <i v-if="sortOrder === 1" @click="sort('Country')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('Country')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
				<th style="text-align: right; width: 230px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-right: 5px">
					2020 Funding <i v-if="sortOrder === 1" @click="sort('Funding')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('Funding')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
				<th style="text-align: right; width: 170px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-right: 5px">
					Annual Fair Share Target <i v-if="sortOrder === 1" @click="sort('AnnualFairShareTarget')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('AnnualFairShareTarget')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
				<th style="text-align: right; width: 300px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-right: 5px">
					Percent of Target Met in 2020 <i v-if="sortOrder === 1" @click="sort('PercentofTargetMet')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('PercentofTargetMet')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
				<th style="text-align: right; width: 200px; vertical-align: top; font-size: 13px; padding-top: 5px; padding-right: 5px">
					Change since 2019<i v-if="sortOrder === 1" @click="sort('Change')" class="mdi mdi-menu-down mdi-18px"></i
					><i v-if="sortOrder === -1" @click="sort('Change')" class="mdi mdi-menu-up mdi-18px"></i>
				</th>
			</tr>
			<tr v-for="(info, index) in sortedTable" :key="index">
				<td
					class="left"
					:class="[{ shaded: info.Rank === 2 }, { 'fair-share-target': info.Rank === 1 || info.Rank === 4 || info.Rank === 7 || info.Rank === 14 || info.Rank === 16 }]"
				>
					{{ info.Rank }}
				</td>
				<td
					class="left"
					:class="[{ shaded: info.Rank === 2 }, { 'fair-share-target': info.Rank === 1 || info.Rank === 4 || info.Rank === 7 || info.Rank === 14 || info.Rank === 16 }]"
				>
					{{ info.Country }}
				</td>
				<td
					class="right"
					:class="[{ shaded: info.Rank === 2 }, { 'fair-share-target': info.Rank === 1 || info.Rank === 4 || info.Rank === 7 || info.Rank === 14 || info.Rank === 16 }]"
				>
					${{ info.Funding }}
				</td>
				<td
					class="right"
					:class="[{ shaded: info.Rank === 2 }, { 'fair-share-target': info.Rank === 1 || info.Rank === 4 || info.Rank === 7 || info.Rank === 14 || info.Rank === 16 }]"
				>
					${{ info.AnnualFairShareTarget }}
				</td>
				<td
					class="right"
					:class="[{ shaded: info.Rank === 2 }, { 'fair-share-target': info.Rank === 1 || info.Rank === 4 || info.Rank === 7 || info.Rank === 14 || info.Rank === 16 }]"
				>
					{{ info.PercentofTargetMet }}%
				</td>
				<td
					class="right"
					:class="[{ shaded: info.Rank === 2 }, { 'fair-share-target': info.Rank === 1 || info.Rank === 4 || info.Rank === 7 || info.Rank === 14 || info.Rank === 16 }]"
				>
					<i v-show="info.Change === 'up'" class="mdi mdi-arrow-top-right mdi-18px"></i>
					<i v-show="info.Change === 'down'" class="mdi mdi-arrow-bottom-right mdi-18px"></i>
					<i v-show="info.Change === 'equal'" class="mdi mdi-minus mdi-18px"></i>
				</td>
			</tr>
		</table>

		<div class="label" style="margin-top: 20px">
			<span> Table includes countries that reported more than $250,000 in TB R&D expenditures to TAG.</span>
		</div>
		<div class="label">
			<span>
				The United Kingdom was the only country that met the target of spending at least 0.1% of overall R&D expenditures on TB research. Countries that did not meet the
				full fair share target but satisfied at least half of the target (0.05%) are shaded.</span
			>
		</div>
		<div class="label">
			<span>
				Fair share funding targets for European Union (EU) member states do not include member state contributions to the EU budget that support spending on TB R&D by the
				European Commission or the European and Development Countries Clinical Trials Partnership.</span
			>
		</div>
	</div>
</template>

<script>
	import table from '@/tables/tabela2.json';
	export default {
		data() {
			return {
				data: table,
				sortBy: 'Rank',
				sortOrder: 1,
			};
		},

		methods: {
			sort: function (sortBy) {
				if (this.sortBy === sortBy) {
					this.sortOrder = -this.sortOrder;
				} else {
					this.sortBy = sortBy;
				}
			},
		},

		computed: {
			sortedTable() {
				return [...this.data]
					.map((i) => ({ ...i, PercentofTargetMet: parseInt(i.PercentofTargetMet) }))
					.map((i) => ({ ...i, Funding: parseInt(i.Funding) }))
					.map((i) => ({ ...i, AnnualFairShareTarget: parseInt(i.AnnualFairShareTarget) }))
					.sort((a, b) => {
						if (a[this.sortBy] >= b[this.sortBy]) {
							return this.sortOrder;
						}
						return -this.sortOrder;
					});
			},
		},
	};
</script>

<style scoped>
	@import '~@mdi/font/css/materialdesignicons.css';
	.content {
		background-color: #18192b;
	}

	.title,
	.subtitle {
		color: white;
		text-align: start;
		margin-left: 10px;
	}

	.title {
		font-size: 26px;
		margin-top: 8px;
		margin-bottom: 0;
		font-family: 'Museo500';
	}

	.head th {
		background-color: #d50032;
		color: white;
		font-family: 'Museo100';
		height: 40px;
		font-weight: 100 !important;
		border-left: 0.5px solid #1e213a;
		border-right: 0.5px solid #1e213a;
	}

	.left {
		text-align: left;
		color: rgb(214, 213, 213);
		font-family: 'Museo100';
		font-weight: 100;
		font-size: 13px;
		padding: 5px;
	}
	.right {
		text-align: right;
		color: rgb(214, 213, 213);
		font-family: 'Museo100';
		font-weight: 100;
		font-size: 13px;
		padding: 5px;
	}
	table {
		border: none;
		border-collapse: collapse;
		margin-left: 10px;
		margin-right: 10px;
	}

	table td {
		border-left: 0.5px solid #1e213a;
		border-right: 0.5px solid #1e213a;
	}

	tr {
		border: solid #292e55;
		border-width: 0.5px 0;
	}

	tr:first-child {
		border-top: none;
	}

	.label {
		color: rgb(214, 213, 213);
		font-family: 'Museo100';
		font-weight: 100;
		font-size: 15px;
		padding: 10px;
		text-align: left;
	}

	.mdi {
		color: white;
	}

	.shaded {
		background-color: #242849;
	}

	.fair-share-target {
		background-color: #4e5274;
	}

	@media only screen and (min-width: 320px) and (max-width: 1440px) {
		.left, .right {
			font-size: 11px;
		}

		.head th{
			font-size: 11px !important;
		}
	}
</style>
