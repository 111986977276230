<script>
	import { Doughnut } from 'vue-chartjs';
	import graph from '@/charts/chart12.json';
	export default {
		extends: Doughnut,
		name: 'ChartFour',
		data: () => ({
			data: graph,
			chartdata: {
				labels: ['Drugs', 'Diagnostics', 'Infrastructure/Unspecified', 'Operational Research & Epidemiology', 'Vaccines', 'Basic Science'],

				datasets: [
					{
						backgroundColor: ['#D50032', '#D9615C', '#ED815B', '#F7CD5B', '#7BCF9C', '#436FF2'],
						data: [],
						borderWidth: 0,
						percent:[27,23,15,15,14,6]
					},
				],
			},

			options: {
				cutoutPercentage: 80,
				legend: {
					display: false,
				},
				responsive: true,
				maintainAspectRatio: false,

				title: {
					display: true,
					text: ['', ''],
					fontColor: 'white',
					fontSize: 20,
					fontFamily: 'Museo100',
				},
				layout: {
					padding: 20,
				},
				tooltips: {
					bodyFontFamily: 'Museo100',
					callbacks: {
						title: function (tooltipItem, data) {
							return data['labels'][tooltipItem[0]['index']];
						},
						label: function (tooltipItem, data) {
							return ' ' + data['datasets'][0]['data'][tooltipItem['index']] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%';
						},
					},
					/* callbacks: {
						label: function (tooltipItem, data) {
							return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] +' | '+   data['datasets'][0]['percent'][tooltipItem['index']] +'%' ;
						},
					}, */
				},
			},
		}),
		mounted() {
			const middleText = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 500).toFixed(2);
				ctx.font = `${fontSize}rem Museo100`;
				ctx.textBaseline = 'middle';

				const text = 'Total funding';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText,
			});
			const middleText2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 400).toFixed(2);
				ctx.font = `${fontSize}rem Museo500`;
				ctx.textBaseline = 'middle';

				const text = '$91.258.186';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.7;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText2,
			});

			const customPluginTitle = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 360).toFixed(2);
				ctx.font = `${fontSize}rem Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Pediatric TB R&D Funding';
				const textX = Math.round((width - ctx.measureText(text).width) / 5);
				const textY = height / 26;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle,
			});
			const customPluginTitle2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 360).toFixed(2);
				ctx.font = `${fontSize}rem Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'by Research Area (M$)';
				const textX = Math.round((width - ctx.measureText(text).width) / 7);
				const textY = height / 9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle2,
			});

			this.chartdata.datasets[0].data.push(this.data[0].Pediatrics);
			this.chartdata.datasets[0].data.push(this.data[1].Pediatrics);
			this.chartdata.datasets[0].data.push(this.data[2].Pediatrics);
			this.chartdata.datasets[0].data.push(this.data[3].Pediatrics);
			this.chartdata.datasets[0].data.push(this.data[4].Pediatrics);
			this.chartdata.datasets[0].data.push(this.data[5].Pediatrics);

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: transparent;
		color: white;
	}
</style>
