<script>
	import { Doughnut } from 'vue-chartjs';
	import graph from '@/charts/chart9.json';
	export default {
		extends: Doughnut,
		name: 'ChartFour',
		data: () => ({
			data: graph,
			chartdata: {
				labels: [
					['U.S. National Institutes', 'of Health (NIH)'],
					['Bill & Melinda Gates Foundation'],
					['Company X'],
					['Unitaid'],
					['Otsuka Pharmaceutical'],
					['European and Developing Countries', 'Clinical Trials Partnership (EDCTP)'],
					['U.K. Foreign, Commonwealth and', 'Development Office (FCDO; formerly DFID)'],
					['U.S. Centers for Disease Control', 'and Prevention (CDC)'],
					['German Federal Ministry', 'of Research and Education (BMBF)'],
					['U.S. Agency for International', 'Development (USAID)'],
					['European Commission'],
					['U.K. Medical Research Council (U.K. MRC)'],
					['Funders under 2%'],
				],

				datasets: [
					{
						backgroundColor: [
							'#D50032',
							'#ED815B',
							'#D9615C',
							'#7BCF9C',
							'#436FF2',
							'#AF71D3',
							'#F77FB0',
							'#5DB9DC',
							'#4C9087',
							'#C7B299',
							'#22B573',
							'#B3B3B3',
							'#F7CD5B',
						],
						data: [],
						borderWidth: 0,
						percent: [24, 22, 10, 7, 6, 5, 4, 2, 2, 2, 2, 2, 12],
					},
				],
			},

			options: {
				cutoutPercentage: 80,
				legend: {
					display: false,
				},
				responsive: true,
				maintainAspectRatio: false,

				title: {
					display: true,
					text: '',
					fontColor: 'white',
					fontSize: 29,
					fontFamily: 'Museo100',
				},

				layout: {
					padding: 20,
				},
				tooltips: {
					bodyFontFamily: 'Museo100',
					callbacks: {
						title: function (tooltipItem, data) {
							return data['labels'][tooltipItem[0]['index']];
						},
						label: function (tooltipItem, data) {
							return ' ' + data['datasets'][0]['data'][tooltipItem['index']] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%';
						},
					},
					/* callbacks: {
						label: function (tooltipItem, data) {
							console.log(tooltipItem.index);
							console.log(data.labels[tooltipItem.index][0]);
							console.log(data.labels[tooltipItem.index][1]);

							if (tooltipItem.index === 0) {
								if (data.labels[tooltipItem.index].length === 2) {
									return [
										data.labels[tooltipItem.index][0],
										data.labels[tooltipItem.index][1],
										data.datasets[0].data[tooltipItem.index] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%',
									];
								} else
									return [
										data.labels[tooltipItem.index][0] +
											': ' +
											data.datasets[0].data[tooltipItem.index] +
											' | ' +
											data['datasets'][0]['percent'][tooltipItem['index']] +
											'%',
									];
							} else {
								console.log();
								if (data.labels[tooltipItem.index].length === 2) {
									return [
										data.labels[tooltipItem.index][0],
										data.labels[tooltipItem.index][1] + ': ',
										data.datasets[0].data[tooltipItem.index] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%',
									];
								} else
									return [
										data.labels[tooltipItem.index][0] +
											': ' +
											data.datasets[0].data[tooltipItem.index] +
											' | ' +
											data['datasets'][0]['percent'][tooltipItem['index']] +
											'%',
									];
							}
						},
					}, */
				},
			},
		}),
		mounted() {
			const middleText = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 500).toFixed(2);
				ctx.font = `${fontSize}rem Museo100`;
				ctx.textBaseline = 'middle';

				const text = 'Total funding';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText,
			});
			const middleText2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 400).toFixed(2);
				ctx.font = `${fontSize}rem Museo500`;
				ctx.textBaseline = 'middle';

				const text = '$329.196.518';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.7;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText2,
			});

			const customPluginTitle = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);
				ctx.font = `${fontSize}rem Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Drugs (M$)';
				const textX = Math.round((width - ctx.measureText(text).width) / 16);
				const textY = height / 20;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle,
			});

			this.chartdata.datasets[0].data.push(this.data[0].Drugs);
			this.chartdata.datasets[0].data.push(this.data[1].Drugs);
			this.chartdata.datasets[0].data.push(this.data[2].Drugs);
			this.chartdata.datasets[0].data.push(this.data[3].Drugs);
			this.chartdata.datasets[0].data.push(this.data[4].Drugs);
			this.chartdata.datasets[0].data.push(this.data[5].Drugs);
			this.chartdata.datasets[0].data.push(this.data[6].Drugs);
			this.chartdata.datasets[0].data.push(this.data[7].Drugs);
			this.chartdata.datasets[0].data.push(this.data[8].Drugs);
			this.chartdata.datasets[0].data.push(this.data[9].Drugs);
			this.chartdata.datasets[0].data.push(this.data[10].Drugs);
			this.chartdata.datasets[0].data.push(this.data[11].Drugs);
			this.chartdata.datasets[0].data.push(this.data[12].Drugs);

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: #18192b;
		color: white;
	}
</style>
