<template>
	<section v-if="size >= 1439" class="body">
		<div class="logos-container">
			<img src="../assets/Ativo1.png" alt="" />
			<img style="margin-left: 30px" src="../assets/Ativo2.png" alt="" />
		</div>

		<div class="head">
			<div class="text-div">
				<div class="vl"></div>
				<span class="title">TUBERCULOSIS RESEARCH </span><br />
				<span class="title-down">FUNDING TRENDS</span><br />
				<span class="subtitle">2005-2020</span>
				<p class="head-text">
					The 16th Annual Tuberculosis Funding Trends Report analyzes funding trends spanning 2005-2020. While funding exceeded $900 milion for the third annual year, it
					falls more than halfway short of the US$2 bilion per year agreed by governments in the 20018 UN High-Level Meeting on TB Political Declaration.
				</p>

				<a href="https://www.treatmentactiongroup.org/resources/tbrd-report/tbrd-report-2021/" download
					><button class="btn"><i class="mdi mdi-tray-arrow-down mdi-16px" style="margin-right: 3px"></i>Download the full report</button></a
				>
			</div>
			<div class="image-div">
				<img class="logo-header" src="../assets/TB3C.png" alt="" />
			</div>
		</div>
		<hr />

		<div>
			<p class="content-text" style="color: white; opacity: 0.8; transform: translateY(-70px)">The graphs below provide a snapshot of key funding trends</p>

			<div style="display: grid; grid-auto-flow: column; justify-content: center; column-gap: 10px">
				<ChartOne id="chart1" class="has-position-relative" style="height: 464px; width: 580px; border: solid 1px #222741" />
				<ChartTwo id="chart2" class="has-position-relative" style="height: 464px; width: 580px; border: solid 1px #222741" />
			</div>

			<!-- <div class="btn-div" style="transform: translateY(-8px); position: relative; z-index: 2">
				<a href="/downloads/Graph3.pdf" download>
					<button class="single-btn" style="transform: translateX(4px)"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>
				<a href="/downloads/Graph4.pdf" download>
					<button class="single-btn" style="transform: translateX(274px)"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>
				<a href="/downloads/Graph5.pdf" download>
					<button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button>
				</a>
			</div> -->
			<div style="display: grid; grid-auto-flow: column; justify-content: center; column-gap: 10px; margin-top: 10px">
				<ChartThree id="chart3" class="has-position-relative" style="height: 464px; width: 580px; border: solid 1px #222741" />
				<ChartFour id="chart4" class="has-position-relative" style="height: 464px; width: 284px; border: solid 1px #222741" />
				<ChartFive id="chart5" class="has-position-relative" style="height: 464px; width: 284px; border: solid 1px #222741" />
			</div>

			<div style="display: grid; grid-auto-flow: column; justify-content: center; column-gap: 10px; margin-top: 10px">
				<ChartSix id="chart6" class="has-position-relative" style="height: 464px; width: 580px; border: solid 1px #222741" />
				<ChartSeven id="chart7" class="has-position-relative" style="height: 464px; width: 284px; border: solid 1px #222741" />
				<ChartEight id="chart8" class="has-position-relative" style="height: 464px; width: 284px; border: solid 1px #222741" />
			</div>

			<div style="display: grid; grid-auto-flow: column; justify-content: center; column-gap: 10px; margin-top: 10px">
				<ChartNine id="chart9" class="has-position-relative" style="height: 464px; width: 283px; border: solid 1px #222741" />
				<ChartTen id="chart10" class="has-position-relative" style="height: 464px; width: 285px; border: solid 1px #222741" />
				<ChartEleven id="chart11" class="has-position-relative" style="height: 464px; width: 283px; border: solid 1px #222741" />
				<ChartTwelve id="chart12" class="has-position-relative" style="height: 464px; width: 283px; border: solid 1px #222741" />
			</div>

			<div class="tables-div" style="display: grid; grid-auto-flow: column; justify-content: center; column-gap: 10px; margin-top: 10px">
				<TableOne id="chart13" class="has-position-relative" style="height: auto; width: 580px; border: solid 1px #222741" />
				<TableTwo id="chart14" class="has-position-relative" style="height: auto; width: 580px; border: solid 1px #222741" />
			</div>
		</div>
	</section>

	<section class="mobile-body" v-else>
		<div class="mobile-logos-container">
			<img class="mobile-logo" src="../assets/Ativo1.png" alt="" />
			<img class="mobile-logo" style="margin-left: 30px" src="../assets/Ativo2.png" alt="" />
		</div>

		<div class="mobile-head">
			<div class="mobile-text-div">
				<div class="mobile-vl"></div>
				<p class="mobile-title">TUBERCULOSIS RESEARCH</p>

				<p class="mobile-title-down">FUNDING TRENDS</p>

				<p class="mobile-subtitle">2005-2020</p>
				<p class="mobile-head-text">
					The 16th Annual Tuberculosis Funding Trends Report analyzes funding trends spanning 2005-2020. While funding exceeded $900 milion for the third annual year, it
					falls more than halfway short of the US$2 bilion per year agreed by governments in the 20018 UN High-Level Meeting on TB Political Declaration.
				</p>

				<a href="/downloads/FullReport.pdf" download
					><button class="mobile-btn"><i class="mdi mdi-tray-arrow-down mdi-16px" style="margin-right: 3px"></i>Download the full report</button></a
				>
			</div>
		</div>

		<div class="mobile-graph">
			<div class="btn-div" style="transform: translate(20px, -10px)">
				<a href="/downloads/Graph1.pdf" download
					><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>
			</div>
			<div>
				<ChartOne style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph2.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartTwo style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph3.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartThree style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph4.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartFour style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph5.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartFive style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph6.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartSix style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph7.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartSeven style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph8.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartEight style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph9.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartNine style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph10.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartTen style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph11.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartEleven style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Graph12.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<ChartTwelve style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Table1.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<TableOne style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
		<br />
		<div class="btn-div" style="transform: translate(20px, -10px)">
			<a href="/downloads/Table2.pdf" download
				><button class="single-btn" style="float: right"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
			></a>
		</div>
		<div class="mobile-graph">
			<div>
				<TableTwo style="height: auto; width: 100%; border: solid 1px #222741" />
			</div>
		</div>
	</section>
</template>

<script>
	import ChartOne from '@/components/CharteOne.vue';
	import ChartTwo from '@/components/ChartTwo.vue';
	import ChartThree from '@/components/ChartThree.vue';
	import ChartFour from '@/components/ChartFour.vue';
	import ChartFive from '@/components/ChartFive.vue';
	import ChartSix from '@/components/ChartSix.vue';
	import ChartSeven from '@/components/ChartSeven.vue';
	import ChartEight from '@/components/ChartEight.vue';
	import ChartNine from '@/components/ChartNine.vue';
	import ChartTen from '@/components/ChartTen.vue';
	import ChartEleven from '@/components/ChartEleven.vue';
	import ChartTwelve from '@/components/ChartTwelve.vue';
	import TableOne from '@/components/TableOne.vue';
	import TableTwo from '@/components/TableTwo.vue';

	export default {
		name: 'Home',
		components: {
			ChartOne,
			ChartTwo,
			ChartThree,
			ChartFour,
			ChartFive,
			ChartSix,
			ChartSeven,
			ChartEight,
			ChartNine,
			ChartTen,
			ChartEleven,
			ChartTwelve,
			TableOne,
			TableTwo,
		},

		data() {
			return {
				size: null,
			};
		},
		methods: {
			getWindowWidth() {
				this.size = window.innerWidth;
				console.log(this.size);
			},

			download() {
				const url = '/src/assets/FullReport.pdf';
				window.location.href = url;
			},
		},
		mounted() {
			window.addEventListener('resize', this.getWindowWidth);
			this.getWindowWidth();
		},
	};

	let onStart = function () {
		// atribui um id ao chart no template e a class has-position-relative
		// coloca todos os botoes como o seguinte, o css é que nao é lido

		document.getElementById('chart1').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; left: 550px;" href="/downloads/Graph1.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);

		document.getElementById('chart2').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph2.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart3').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph3.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart4').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph4.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart5').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph5.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart6').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph6.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart7').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph7.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart8').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph8.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart9').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph9.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart10').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph10.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart11').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph11.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart12').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Graph12.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart13').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Table1.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
		document.getElementById('chart14').insertAdjacentHTML(
			'afterbegin',
			`<a style="position: absolute; top: 0; right: 0;" href="/downloads/Table2.pdf" download
					><button class="single-btn" style="margin-top: 0px; background-color:#d50032;border:none;color:white;cursor:pointer"><i class="mdi mdi-tray-arrow-down mdi-16px"></i></button
				></a>`
		);
	};

	document.addEventListener('DOMContentLoaded', onStart, false);
</script>

<style scoped>
	@import '~@mdi/font/css/materialdesignicons.css';

	.title {
		color: #d50032;
		font-size: 52px;
		font-family: 'Museo700';
		margin-left: 20px !important;
	}
	.title-down {
		color: #d50032;
		font-size: 52px;
		font-family: 'Museo700';
		margin-left: 28px !important;
	}
	.subtitle {
		color: #d50032;
		font-size: 53px;
		font-family: 'Museo100';
		margin-left: 30px !important;
	}

	.head {
		text-align: left;
		padding-left: 8%;
		margin-bottom: 50px;
		display: inline-flex;
	}

	span {
		font-size: 30px;
	}

	.body {
		background-color: #111323;
		max-height: 100%;
		padding-bottom: 76px;
	}

	.mobile-body {
		background-color: #111323;
	}

	.vl {
		border-left: 4px solid #d50032;
		height: 180px;
		display: inline-flex;
		transform: translateY(137px);
	}

	.text-div {
		width: 55%;
	}

	.image-div {
		width: 45%;
	}

	.head-text {
		color: white;
		opacity: 0.8;
		width: 80%;
		line-height: 26px;
	}

	.logo-header {
		width: 80%;
		transform: translateY(-120px);
	}

	.btn {
		background-color: #d50032;
		border: none;
		color: white;
		opacity: 0.8;
		height: 24px;
		margin-top: 20px;
		cursor: pointer;
	}

	.single-btn {
		background-color: #d50032;
		border: none;
		color: white;
		opacity: 0.8;
		height: 22px;
		margin-top: 20px;
		cursor: pointer;
	}

	hr {
		width: 83%;
		background-color: #d50032;
		height: 1px;
		border: none;
		transform: translateY(-140px);
	}

	.btn-div {
		width: 83%;
		margin: 0 auto;
		margin-bottom: 2px;
	}

	/* 	.content {
		transform: translateY(-137px);
	} */

	.last-download-btn {
		transform: translateY(-131px);
		position: relative;
		z-index: 2;
	}

	/* 	.tables-div {
		top: -28px;
	} */

	.mobile-graph {
		width: 100%;
	}

	.logos-container {
		text-align: left;
		padding-left: 8%;
		padding-top: 40px;
	}

	.mobile-logos-container {
		display: inline-flex;
		justify-content: center;
	}

	.mobile-logo {
		width: 30%;
		object-fit: scale-down;
	}

	.mobile-head {
		display: inline-flex;
	}

	.mobile-vl {
		border-left: 4px solid #d50032;
		height: 62px;
		transform: translateY(67px);
	}

	.mobile-title {
		color: #d50032;
		font-size: 20px;
		font-family: 'Museo700';
		margin: 0;
		text-align: left;
		margin-left: 6px;
	}
	.mobile-title-down {
		color: #d50032;
		font-size: 20px;
		font-family: 'Museo700';
		margin: 0;
		text-align: left;
		margin-left: 6px;
	}
	.mobile-subtitle {
		color: #d50032;
		font-size: 20px;
		font-family: 'Museo100';
		margin: 0;
		text-align: left;
		margin-left: 6px;
	}

	.mobile-text-div {
		width: 100%;
		text-align: left;
	}

	.mobile-head-text {
		color: white;
		opacity: 0.8;
		text-align: left;
	}

	.mobile-btn {
		background-color: #d50032;
		border: none;
		color: white;
		opacity: 0.8;
		height: 24px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@media only screen and (min-width: 320px) and (max-width: 1440px) {
		.head {
			padding-left: 8%;
		}

		.title,
		.title-down,
		.subtitle {
			font-size: 33px;
		}

		.text-div {
			width: 100%;
		}

		.head-text {
			font-size: 15px;
		}

		.title {
			margin-left: 10px !important;
		}

		.title-down,
		.subtitle {
			margin-left: 15px !important;
		}

		/* .content {
			transform: translateY(0px);
		} */

		.head-text {
			margin-left: 10px;
		}

		.logo-header {
			transform: translate(-32px, -80px);
		}

		.btn {
			margin-top: 0;
		}

		hr {
			width: 84%;
		}

		.text-div {
			transform: translateY(-40px);
		}

		.vl {
			height: 130px;
			transform: translateY(96px);
		}
	}

	@media only screen and (min-width: 1500px) {
		.head {
			padding-left: 18%;
		}

		.logos-container {
			padding-left: 18%;
		}

		.logo-header {
			width: 55%;
			transform: translateY(-90px);
		}

		.text-div {
			width: 50%;
		}

		.image-div {
			width: 52%;
		}

		hr {
			width: 63%;
			transform: translate(-20px, -150px);
		}

		.btn {
			margin-top: 0px;
		}

		.btn-div {
			width: 66%;
		}
	}

	@media only screen and (min-width: 1920px) {
		.btn-div {
			width: 62%;
		}
		/* .content {
			transform: translateY(-120px);
		} */

		/* 	.tables-div {
			top: -48px;
		} */

		.last-download-btn {
			transform: translateY(-133px);
		}

		hr {
			transform: translate(-9px, -150px);
		}
	}

	@media only screen and (min-width: 2500px) {
		.head,
		.logos-container {
			padding-left: 27%;
		}

		.logo-header {
			width: 45%;
			transform: translateY(-120px);
		}

		.text-div {
			width: 45%;
		}

		.image-div {
			width: 40%;
		}

		.btn-div {
			width: 47%;
		}
	}

	.has-position-relative {
		position: relative;
	}
</style>
