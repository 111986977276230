<script>
	import { Line } from 'vue-chartjs';
	import graph from '@/charts/chart2.json';
	export default {
		extends: Line,
		name: 'ChartTwo',
		data: () => ({
			data: graph,
			chartdata: {
				labels: [],
				datasets: [
					{
						label: 'Basic Science ',
						yAxisID: 'A',
						data: [],
						borderColor: '#D50032',
						backgroundColor: '#1C00ff00',
						order: 1,
						pointBackgroundColor: '#D50032',
					},
					{
						label: 'Infrastructure/ Unspecified',
						data: [],
						borderColor: '#ED815B',
						backgroundColor: '#1C00ff00',
						type: 'line',
						order: 1,
						pointBackgroundColor: '#ED815B',
					},
					{
						label: 'Diagnostics',
						data: [],
						borderColor: '#F7CD5B',
						backgroundColor: '#1C00ff00',
						type: 'line',
						order: 1,
						pointBackgroundColor: '#F7CD5B',
					},
					{
						label: 'Drugs',
						data: [],
						borderColor: '#7BCF9C',
						backgroundColor: '#1C00ff00',
						type: 'line',
						order: 1,
						pointBackgroundColor: '#7BCF9C',
					},
					{
						label: 'Vaccinces',
						data: [],
						borderColor: '#436FF2',
						backgroundColor: '#1C00ff00',
						type: 'line',
						order: 1,
						pointBackgroundColor: '#436FF2',
					},
					{
						label: 'Operational research & Epidemiology',
						data: [],
						borderColor: '#AF71D3',
						backgroundColor: '#1C00ff00',
						type: 'line',
						order: 1,
						pointBackgroundColor: '#AF71D3',
					},
				],
			},

			options: {
				legend: {
					labels: {
						fontColor: 'white',
						fontSize: 16,
						fontFamily: 'Museo100',
						boxWidth: 3,
					},
					position: 'bottom',
					align: 'start',
				},
				responsive: true,
				maintainAspectRatio: false,

				title: {
					display: true,
					text: ['', '', ''],
					fontColor: 'white',
					fontSize: 29,
					fontFamily: 'Museo100',
				},

				scales: {
					yAxes: [
						{
							id: 'A',

							position: 'left',
							ticks: {
								maxTicksLimit: 7,

								fontStyle: 'normal',
								fontFamily: 'Museo100',
							},
						},
					],
					xAxes: [
						{
							ticks: {
								maxTicksLimit: 10,

								fontStyle: 'normal',
								fontFamily: 'Museo100',
							},
						},
					],
				},
				tooltips: {
					bodyFontFamily: 'Museo100',
				},
			},
		}),

		methods: {
			getWindowWidth() {
				this.size = window.innerWidth;

				if (this.size > 1439) {
					this.options.legend.labels.fontSize = 16;
				} else this.options.legend.labels.fontSize = 12;
			},
		},
		mounted() {
			this.getWindowWidth();
			const customPlugin = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);
				if (this.size > 1439) {
					ctx.font = `${fontSize}rem Museo300`;
				} else ctx.font = `${16}px Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Total TB R&D Funding by Research Area (M$)';
				const textX = Math.round((width - ctx.measureText(text).width) / 8);
				const textY = height / 15;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: customPlugin,
			});
			const customPluginTwo = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 370).toFixed(2);
				if (this.size > 1439) {
					ctx.font = `${fontSize}rem Museo300`;
				} else ctx.font = `${16}px Museo300`;
				ctx.textBaseline = 'left';

				const text = '2009-2020';
				const textX = Math.round((width - ctx.measureText(text).width) / 34);
				const textY = height / 7;
				ctx.fillStyle = '#B3B3B3';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin2',
				beforeDraw: customPluginTwo,
			});

			for (let index = 0; index < this.data.length; index++) {
				if (this.data[index].Year !== null) {
					this.chartdata.labels.push(this.data[index].Year);
					this.chartdata.datasets[0].data.push(this.data[index].BasicScience);
					this.chartdata.datasets[1].data.push(this.data[index].Infrastructure.Unspecified);
					this.chartdata.datasets[2].data.push(this.data[index].Diagnostics);
					this.chartdata.datasets[3].data.push(this.data[index].Drugs);
					this.chartdata.datasets[4].data.push(this.data[index].Vaccines);
					this.chartdata.datasets[5].data.push(this.data[index].OperationalResearchEpidemiology);
				}
			}

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: #18192b;
		color: white;
	}
</style>
