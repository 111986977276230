<script>
	import { Doughnut } from 'vue-chartjs';
	import graph from '@/charts/chart8.json';
	export default {
		extends: Doughnut,
		name: 'ChartFour',
		data: () => ({
			info: null,
			data: graph,
			middleText: null,
			chartdata: {
				labels: [
					['U.S. National Institutes', 'of Health (NIH)'],
					['European and Developing Countries', 'Clinical Trials Partnership (EDCTP)'],
					['Oxford Immunotec'],
					['Bill & Melinda Gates Foundation'],
					['U.K. Foreign, Commonwealth and', 'Development Office (FCDO; formerly DFID)'],
					['Unitaid'],
					['U.S. Centers for Disease Control', 'and Prevention (CDC)'],
					['QIAGEN'],
					['European Commission'],
					['Funders under 3%'],
				],

				datasets: [
					{
						backgroundColor: ['#D50032', '#AF71D3', '#D9615C', '#ED815B', '#7BCF9C', '#436FF2', '#F77FB0', '#5DB9DC', '#F7CD5B', '#4C9087'],
						data: [],
						borderWidth: 0,
						percent: [32, 11, 8, 6, 6, 4, 3, 3, 3, 24],
					},
				],
			},

			options: {
				cutoutPercentage: 80,
				legend: {
					display: false,
				},
				responsive: true,
				maintainAspectRatio: false,

				title: {
					display: true,
					text: '',
					fontColor: 'white',
					fontSize: 29,
					fontFamily: 'Museo100',
				},

				layout: {
					padding: 20,
				},
				tooltips: {
					bodyFontFamily: 'Museo100',
					callbacks: {
						title: function (tooltipItem, data) {
							return data['labels'][tooltipItem[0]['index']];
						},
						label: function (tooltipItem, data) {
							return ' ' + data['datasets'][0]['data'][tooltipItem['index']] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%';
						},
					},

					/* callbacks: {
						label: function (tooltipItem, data) {
							console.log(tooltipItem.index);
							console.log(data.labels[tooltipItem.index][0]);
							console.log(data.labels[tooltipItem.index][1]);

							if (tooltipItem.index === 0) {
								if (data.labels[tooltipItem.index].length === 2) {
									return [
										data.labels[tooltipItem.index][0],
										data.labels[tooltipItem.index][1],
										data.datasets[0].data[tooltipItem.index] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%',
									];
								} else
									return [
										data.labels[tooltipItem.index][0] +
											': ' +
											data.datasets[0].data[tooltipItem.index] +
											' | ' +
											data['datasets'][0]['percent'][tooltipItem['index']] +
											'%',
									];
							} else {
								console.log();
								if (data.labels[tooltipItem.index].length === 2) {
									return [
										data.labels[tooltipItem.index][0],
										data.labels[tooltipItem.index][1] + ': ',
										data.datasets[0].data[tooltipItem.index] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%',
									];
								} else
									return [
										data.labels[tooltipItem.index][0] +
											': ' +
											data.datasets[0].data[tooltipItem.index] +
											' | ' +
											data['datasets'][0]['percent'][tooltipItem['index']] +
											'%',
									];
							}
						},
					}, */
				},

				onClick(e, array) {
					if (array.length != 0) {
						var position = array[0]._index;
						/* 	var activeElement = this.tooltip._data.datasets[0].data[position]; */
						var activeElement = graph[position].Percent;
						console.log(position);

						console.log(activeElement);

						/* if (e.type == 'click') {
							Chart.pluginService.register({
								afterEvent: function (chart) {
									const { width, height, ctx } = chart.chart;

									const fontSize = (height / 350).toFixed(2);
									ctx.font = `${fontSize}rem sans-serif`;
									ctx.textBaseline = 'middle';
									let text = activeElement;
									const textX = Math.round((width - ctx.measureText(text).width) / 16);
									const textY = height / 10;
									ctx.fillStyle = 'rgb(240, 240, 240)';

									ctx.fillText(text, textX, textY);
									ctx.save();
								},
							});
						} */
					} else {
						console.log('You selected the background!');
					}
				},
			},
		}),
		mounted() {
			const customPluginTitle = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);
				ctx.font = `${fontSize}rem Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Diagnostics';
				const textX = Math.round((width - ctx.measureText(text).width) / 16);
				const textY = height / 20;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle,
			});
			const middleText = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 500).toFixed(2);
				ctx.font = `${fontSize}rem Museo100`;
				ctx.textBaseline = 'middle';

				const text = 'Total funding';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText,
			});

			const middleText2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 400).toFixed(2);
				ctx.font = `${fontSize}rem Museo500`;
				ctx.textBaseline = 'middle';

				const text = '$129.391.183';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.7;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText2,
			});

			this.chartdata.datasets[0].data.push(this.data[0].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[1].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[2].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[3].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[4].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[5].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[6].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[7].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[8].Diagnostics);
			this.chartdata.datasets[0].data.push(this.data[9].Diagnostics);

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: #18192b;
		color: white;
	}
</style>
