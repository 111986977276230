<script>
	import { Bar } from 'vue-chartjs';
	import graph1 from '@/charts/chart1.json';
	export default {
		extends: Bar,
		name: 'ChartOne',
		data: () => ({
			fontSize: 0,
			size: 0,
			data: graph1,
			chartdata: {
				labels: [],
				datasets: [
					{
						label: 'Nominal funding (current dollars) ',
						yAxisID: 'A',
						data: [],
						backgroundColor: '#D50032',
						order: 1,
					},
					{
						label: 'Funding adjusted for inflation (2005 constant dollars)',
						data: [],
						borderColor: '#436FF2',
						backgroundColor: '#1C00ff00',
						type: 'line',
						order: 0,
						pointBackgroundColor: '#436FF2',
					},
				],
			},

			options: {
				legend: {
					labels: {
						fontColor: 'white',
						fontSize: 16,
						fontFamily: 'Museo100',
						boxWidth: 3,
					},
					position: 'bottom',
					align: 'start',
				},

				responsive: true,
				maintainAspectRatio: false,

				title: {
					display: true,
					text: ['', '', ''],
					fontColor: 'white',
					fontSize: 29,
					fontFamily: 'Museo100',
					align: 'start',
				},

				scales: {
					yAxes: [
						{
							id: 'A',
							type: 'linear',
							position: 'left',
							ticks: {
								maxTicksLimit: 5,

								fontStyle: 'normal',
								fontFamily: 'Museo100',
							},
						},
					],
					xAxes: [
						{
							ticks: {
								maxTicksLimit: 5,
								fontStyle: 'normal',
								fontFamily: 'Museo100',
							},
						},
					],
				},

				tooltips: {
					bodyFontFamily: 'Museo100',
				},
			},
		}),

		methods: {
			getWindowWidth() {
				this.size = window.innerWidth;

				if (this.size > 1439) {
					this.options.legend.labels.fontSize = 16;
				} else this.options.legend.labels.fontSize = 12;
			},
		},
		mounted() {
			this.getWindowWidth();
			const customPlugin = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);

				if (this.size > 1439) {
					ctx.font = `${fontSize}rem Museo300`;
				} else ctx.font = `${16}px Museo300`;

				ctx.textBaseline = 'middle';

				const text = 'Total TB R&D Funding (M$)';
				const textX = Math.round((width - ctx.measureText(text).width) / 16);
				const textY = height / 15;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: customPlugin,
			});
			const customPluginTwo = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 370).toFixed(2);
				if (this.size > 1439) {
					ctx.font = `${fontSize}rem Museo300`;
				} else ctx.font = `${16}px Museo300`;

				ctx.textBaseline = 'left';

				const text = '2005-2020';
				const textX = Math.round((width - ctx.measureText(text).width) / 28);
				const textY = height / 7;
				ctx.fillStyle = '#B3B3B3';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin2',
				beforeDraw: customPluginTwo,
			});

			for (let index = 0; index < this.data.length; index++) {
				this.chartdata.labels.push(this.data[index].Year);
				this.chartdata.datasets[0].data.push(this.data[index].Nominal);
				this.chartdata.datasets[1].data.push(this.data[index].Funding);
			}

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: #111323;
		color: white;
	}
</style>
