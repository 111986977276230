<script>
	import { Doughnut } from 'vue-chartjs';
	import graph from '@/charts/chart5.json';
	export default {
		extends: Doughnut,
		name: 'ChartFour',
		data: () => ({
			data: graph,
			chartdata: {
				labels: ['Drugs', 'Basic Science', 'Diagnostics', 'Operational Research & Epidemiology', 'Vaccines', 'Infrastructure/Unspecified'],

				datasets: [
					{
						backgroundColor: ['#D50032', '#AF71D3', '#7BCF9C', '#ED815B', '#436FF2', '#F7CD5B'],
						data: [],
						borderWidth: 0,
						percent: [36, 18, 14, 13, 13, 6],
					},
				],
			},

			options: {
				cutoutPercentage: 80,
				legend: {
					display: false,
				},
				responsive: true,
				maintainAspectRatio: false,

				title: {
					display: true,
					text: ['', ''],
					fontColor: 'white',
					fontSize: 18,
					fontFamily: 'Museo100',
				},
				layout: {
					padding: 20,
				},

				tooltips: {
					bodyFontFamily: 'Museo100',
					callbacks: {
						title: function (tooltipItem, data) {
							return data['labels'][tooltipItem[0]['index']];
						},
						label: function (tooltipItem, data) {
							return ' ' + data['datasets'][0]['data'][tooltipItem['index']] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%';
						},
					},
					/* 	callbacks: {
						label: function (tooltipItem, data) {
							return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] +' | '+   data['datasets'][0]['percent'][tooltipItem['index']] +'%' ;
						},
					}, */
				},
			},
		}),
		
		mounted() {
			const middleText = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 500).toFixed(2);
				ctx.font = `${fontSize}rem Museo100`;
				ctx.textBaseline = 'middle';

				const text = 'Total funding';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText,
			});
			const middleText2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 400).toFixed(2);
				ctx.font = `${fontSize}rem Museo500`;
				ctx.textBaseline = 'middle';

				const text = '$915.325.165';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.7;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText2,
			});

			const customPluginTitle = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);
				ctx.font = `${fontSize}rem Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Total TB R&D Funding ';
				const textX = Math.round((width - ctx.measureText(text).width) / 16);
				const textY = height / 20;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle,
			});
			const customPluginTitle2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 320).toFixed(2);
				ctx.font = `${fontSize}rem Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'by Research Area (M$)';
				const textX = Math.round((width - ctx.measureText(text).width) / 16);
				const textY = height / 9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle2,
			});

			const customPluginTwo = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 370).toFixed(2);
				ctx.font = `${fontSize}rem Museo100`;
				ctx.textBaseline = 'left';

				const text = '2020';
				const textX = Math.round((width - ctx.measureText(text).width) / 26);
				const textY = height / 5.3;
				ctx.fillStyle = '#B3B3B3';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin2',
				beforeDraw: customPluginTwo,
			});

			this.chartdata.datasets[0].data.push(this.data[2].TotalFunding);
			this.chartdata.datasets[0].data.push(this.data[0].TotalFunding);
			this.chartdata.datasets[0].data.push(this.data[1].TotalFunding);
			this.chartdata.datasets[0].data.push(this.data[4].TotalFunding);
			this.chartdata.datasets[0].data.push(this.data[5].TotalFunding);
			this.chartdata.datasets[0].data.push(this.data[3].TotalFunding);

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: transparent;
		color: white;
	}
</style>
