<script>
	import { Doughnut } from 'vue-chartjs';
	import graph from '@/charts/chart11.json';
	export default {
		extends: Doughnut,
		name: 'ChartFour',
		data: () => ({
			data: graph,
			chartdata: {
				labels: [
					['U.S. National Institutes of Health (NIH)'],
					['Unitaid'],
					['Global Affairs Canada'],
					['Bill & Melinda Gates Foundation'],
					['European and Developing Countries', 'Clinical Trials Partnership (EDCTP)'],
					['Indian Ministry of Health', 'and Family Welfare (MOHFW)'],
					['U.S. Agency for International', 'Development (USAID)'],
					['U.S. Centers for Disease Control', 'and Prevention (CDC)'],
					['Global Fund to Fight AIDS,', 'Tuberculosis and Malaria (Global Fund)'],
					['Funders under 3%'],
				],

				datasets: [
					{
						backgroundColor: ['#D50032', '#D9615C', '#ED815B', '#F7CD5B', '#7BCF9C', '#436FF2', '#5DB9DC', '#4C9087', '#AF71D3', '#F77FB0'],
						data: [],
						borderWidth: 0,
						percent: [31, 11, 11, 6, 5, 5, 5, 4, 3, 19],
					},
				],
			},

			options: {
				cutoutPercentage: 80,
				legend: {
					display: false,
				},
				responsive: true,
				maintainAspectRatio: false,

				title: {
					display: true,
					text: ['', ' '],
					fontColor: 'white',
					fontSize: 20,
					fontFamily: 'Museo100',
				},
				layout: {
					padding: 20,
				},
				tooltips: {
					bodyFontFamily: 'Museo100',
					callbacks: {
						title: function (tooltipItem, data) {
							return data['labels'][tooltipItem[0]['index']];
						},
						label: function (tooltipItem, data) {
							return ' ' + data['datasets'][0]['data'][tooltipItem['index']] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%';
						},
					},
					/* callbacks: {
						label: function (tooltipItem, data) {
							console.log(tooltipItem.index);
							console.log(data.labels[tooltipItem.index][0]);
							console.log(data.labels[tooltipItem.index][1]);
							if (tooltipItem.index === 0) {
								if (data.labels[tooltipItem.index].length === 2) {
									return [
										data.labels[tooltipItem.index][0],
										data.labels[tooltipItem.index][1],
										data.datasets[0].data[tooltipItem.index] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%',
									];
								} else
									return [
										data.labels[tooltipItem.index][0] +
											': ' +
											data.datasets[0].data[tooltipItem.index] +
											' | ' +
											data['datasets'][0]['percent'][tooltipItem['index']] +
											'%',
									];
							} else {
								console.log();
								if (data.labels[tooltipItem.index].length === 2) {
									return [
										data.labels[tooltipItem.index][0],
										data.labels[tooltipItem.index][1] + ': ',
										data.datasets[0].data[tooltipItem.index] + ' | ' + data['datasets'][0]['percent'][tooltipItem['index']] + '%',
									];
								} else
									return [
										data.labels[tooltipItem.index][0] +
											': ' +
											data.datasets[0].data[tooltipItem.index] +
											' | ' +
											data['datasets'][0]['percent'][tooltipItem['index']] +
											'%',
									];
							}
						},
					}, */
				},
			},
		}),
		mounted() {
			const middleText = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 500).toFixed(2);
				ctx.font = `${fontSize}rem Museo100`;
				ctx.textBaseline = 'middle';

				const text = 'Total funding';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText,
			});
			const middleText2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 400).toFixed(2);
				ctx.font = `${fontSize}rem Museo500`;
				ctx.textBaseline = 'middle';

				const text = '$119.418.766';
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 1.7;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};

			this.addPlugin({
				id: 'my-plugin',
				beforeDraw: middleText2,
			});

			const customPluginTitle = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 360).toFixed(2);
				ctx.font = `${fontSize}rem Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Operational Research &';
				const textX = Math.round((width - ctx.measureText(text).width) / 6);
				const textY = height / 24;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle,
			});
			const customPluginTitle2 = (chart) => {
				const { width, height, ctx } = chart.chart;
				ctx.restore();
				const fontSize = (height / 360).toFixed(2);
				ctx.font = `${fontSize}rem Museo300`;
				ctx.textBaseline = 'middle';

				const text = 'Epidemiology (M$)';
				const textX = Math.round((width - ctx.measureText(text).width) / 10);
				const textY = height / 9;
				ctx.fillStyle = 'rgb(240, 240, 240)';
				ctx.fillText(text, textX, textY);
				ctx.save();
			};
			this.addPlugin({
				id: 'my-plugin-title',
				beforeDraw: customPluginTitle2,
			});

			this.chartdata.datasets[0].data.push(this.data[0].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[1].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[2].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[3].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[4].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[5].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[6].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[7].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[8].OperationalResearchEpidemiology);
			this.chartdata.datasets[0].data.push(this.data[9].OperationalResearchEpidemiology);

			this.renderChart(this.chartdata, this.options);
		},
	};
</script>

<style scoped>
	canvas {
		background-color: #18192b;
		color: white;
	}
</style>
